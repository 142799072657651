import { atom } from "jotai";
import { proxy, snapshot, subscribe } from "valtio/vanilla";
import create from "zustand/vanilla";
import { persist } from "zustand/middleware";
import { State } from "zustand";
import { userbase } from "../userbase";
import { useState } from "react";
import { Item } from "userbase-js";
import { v4 as uuid } from "uuid";

export function persistedAtom(key: string, defaultValue: string) {
  const persisted = atom<string, string>(
    localStorage.getItem(key) || defaultValue,
    (_get, set, update) => {
      localStorage.setItem(key, update);
      set(persisted, update);
    }
  );
  return persisted;
}

export function persistedProxy<T extends object>(key: string, defaultValue: T) {
  const jsonStr = localStorage.getItem(key);
  const obj = jsonStr ? JSON.parse(jsonStr) : defaultValue;
  const proxied = proxy<T>(obj);
  subscribe(proxied, () => {
    const snap = snapshot(proxied);
    console.log("saving", snap);
    localStorage.setItem(key, JSON.stringify(snap));
  });
  return proxied;
}

export function persistedState<T extends State>(key: string, defaultValue: T) {
  const jsonStr = localStorage.getItem(key);
  const obj: T = jsonStr ? JSON.parse(jsonStr).state : defaultValue;
  return create<T>(
    persist<T>(() => obj, { name: key })
  );
}

export function tPersistedAtom<
  T extends string | boolean | number | number[] | null
>(key: string, defaultValue: T) {
  const persisted = atom<T, T>(
    () => {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : defaultValue;
    },
    (_get, set, update) => {
      localStorage.setItem(key, JSON.stringify(update));
      set(persisted, update);
    }
  );
  return persisted;
}

export function getNumeric(num: string | null): number | null {
  return num && !isNaN(Number(num)) ? Number(num) : null;
}

export interface UserbaseItem<T> extends Item {
  item: T;
}

export function userbaseInsertHook<T>(
  dbName: string | ((prefix?: string) => string)
): (
  key?: string
) => [
  (item: T) => Promise<void | string>,
  { loading: boolean; error: string }
] {
  return (prefix?: string) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const insert = async (item: T) => {
      try {
        setLoading(true);
        const itemId = uuid();
        await userbase.insertItem({
          databaseName: typeof dbName === "function" ? dbName(prefix) : dbName,
          itemId,
          item,
        });
        setLoading(false);
        return itemId;
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    };
    return [insert, { loading, error }];
  };
}

export function userbaseUpdateHook<T>(
  dbName: string | ((prefix?: string) => string)
): (
  key?: string
) => [
  (itemId: string, item: T) => Promise<void | string>,
  { loading: boolean; error: string }
] {
  return (prefix?: string) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const update = async (itemId: string, item: T) => {
      try {
        setLoading(true);
        await userbase.updateItem({
          databaseName: typeof dbName === "function" ? dbName(prefix) : dbName,
          itemId,
          item,
        });
        setLoading(false);
        return itemId;
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    };
    return [update, { loading, error }];
  };
}

export function userbaseDeleteHook(
  dbName: string | ((prefix?: string) => string)
): (
  key?: string
) => [(itemId: string) => Promise<void>, { loading: boolean; error: string }] {
  return (prefix?: string) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const insert = async (itemId: string) => {
      try {
        setLoading(true);
        await userbase.deleteItem({
          databaseName: typeof dbName === "function" ? dbName(prefix) : dbName,
          itemId,
        });
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    };
    return [insert, { loading, error }];
  };
}
