import { EuiHeaderLink, EuiHeaderLinkProps } from "@elastic/eui";
import React, { MouseEvent } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

const isModifiedEvent = (event: MouseEvent) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

const isLeftClickEvent = (event: MouseEvent) => event.button === 0;

// const isTargetBlank = (event: MouseEvent) => {
//   const target = event.target.getAttribute('target');
//   return target && target !== '_self';
// };

const HeaderLink: React.FC<EuiHeaderLinkProps> = ({ href, ...rest }) => {
  const history = useHistory();
  const routeMatch = useRouteMatch(href!);

  function onClick(event: MouseEvent) {
    if (event.defaultPrevented || !href) {
      return;
    }

    // Let the browser handle links that open new tabs/windows
    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    // Prevent regular link behavior, which causes a browser refresh.
    event.preventDefault();

    // Push the route to the history.
    history.push(href);
  }

  // Generate the correct link href (with basename accounted for)
  const newHref = history.createHref({ pathname: href });

  const props = { ...rest, href: newHref, onClick, isActive: routeMatch?.isExact };

  return <EuiHeaderLink {...props} />
};

export default HeaderLink;
