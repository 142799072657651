import { EuiComboBox, EuiFormRow } from "@elastic/eui";
import { Market } from "ccxt";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { binance } from "../utils/exchange";
import { symbolAtom } from "../utils/state";

interface SymbolSelectProps {}

const SymbolSelect: React.FC<SymbolSelectProps> = () => {
  const [markets, setMarkets] = useState<Market[]>([]);
  const [symbol, setSymbol] = useAtom(symbolAtom);
  useEffect(() => {
    binance.fetchMarkets().then(setMarkets);
  }, []);
  return (
    <EuiFormRow fullWidth>
      <EuiComboBox
      style={{ width: 200 }}
        compressed
        isClearable={false}
        placeholder="Select symbol"
        singleSelection={{ asPlainText: true }}
        options={markets.map(m => ({ label: m.symbol }))}
        selectedOptions={[{ label: symbol }]}
        onChange={([option]) => setSymbol(option.label)}
      />
    </EuiFormRow>
  );
};

export default SymbolSelect;