import { useAtom } from "jotai";
import React from "react";
import { capitalAtom, riskAtom } from "../utils/state";
import { EuiFieldText, EuiFormRow, EuiHideFor, EuiPanel } from "@elastic/eui";
import SymbolSelect from "./SymbolSelect";

const RiskCapital: React.FC = () => {
  const [capital, setCapital] = useAtom(capitalAtom);
  const [risk, setRisk] = useAtom(riskAtom);

  return (
    <EuiPanel>
      <EuiHideFor sizes={["l", "xl"]}>
        <SymbolSelect />
      </EuiHideFor>
      <EuiFormRow label="Capital" display="columnCompressed" fullWidth>
        <EuiFieldText
          name="capital"
          prepend="$"
          compressed
          value={capital}
          onChange={(evt) => setCapital(evt.target.value)}
        />
      </EuiFormRow>
      <EuiFormRow label="Risk" display="columnCompressed" fullWidth>
        <EuiFieldText
          name="risk"
          append="%"
          compressed
          value={risk}
          onChange={(evt) => setRisk(evt.target.value)}
        />
      </EuiFormRow>
    </EuiPanel>
  );
};

export default RiskCapital;
