import Layout from "./components/Layout";

import Settings from "./components/Settings";
import { showSignInAtom, showSignUpAtom, userAtom } from "./utils/state";
import SignInModal from "./components/SignInModal";
import SignUpModal from "./components/SignUpModal";
import { useAtom } from "jotai";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import React from "react";
import Chart from "./pages/Chart";
import JournalsPage from "./pages/Journals";
import { useAtomValue } from "jotai/utils";

function App() {
  const [showSignIn, setShowSignIn] = useAtom(showSignInAtom);
  const [showSignUp, setShowSignUp] = useAtom(showSignUpAtom);
  const { user } = useAtomValue(userAtom);

  return (
    <Router>
      <Layout title="Position Size Calculator">
        <Header />
        <div style={{ marginTop: 40 }}>
        <Switch>
          <Route exact path="/">
            <Chart />
          </Route>
          <Route path="/journals">
            {user && <JournalsPage />}
          </Route>
        </Switch>
        </div>
        <Settings />
        <SignInModal open={showSignIn} onClose={() => setShowSignIn(false)} />
        <SignUpModal open={showSignUp} onClose={() => setShowSignUp(false)} />
      </Layout>
    </Router>
  );
}

export default App;
