import { atom } from "jotai";
import { atomFamily, useAtomValue } from "jotai/utils";
import { userbase } from "../userbase";
import {
  userbaseDeleteHook,
  userbaseInsertHook,
  UserbaseItem,
  userbaseUpdateHook,
} from "./helpers";

interface Database<T> {
  loading?: boolean;
  error?: string;
  data: UserbaseItem<T>[];
}

function dbAtom<T>(name: string) {
  const db = atom<Database<T>>({ data: [], loading: true });
  db.onMount = (set) => {
    let newItems: any[] = [];
    userbase
      .openDatabase({
        databaseName: name,
        changeHandler: (items) => {
          newItems = items;
          set({ data: items });
        },
      })
      .then(() => {
        set({
          loading: false,
          data: newItems,
        });
      });
  };
  return db;
}

export interface Journal {
  name: string;
  startingBalance: number;
}

export interface Trade {
  name?: string;
  symbol?: string;
  entryDate?: string;
  entryQuantity?: string;
  entryPrice?: string;
  exitDate?: string;
  exitPrice?: string;
  fees?: string;
  notes?: string;
  tags?: string[];
}

export const journalsDbAtom = dbAtom<Journal>("journals");
export const journalDbAtom = atomFamily<string, UserbaseItem<Journal> | null>(
  (id) => {
    return atom((get) => {
      const { data, loading } = get(journalsDbAtom);
      if (loading) {
        return null;
      }
      return data.find((j) => j.itemId === id) || null;
    });
  }
);

export const useAddJournal = userbaseInsertHook<Journal>("journals");
export const useDeleteJournal = userbaseDeleteHook("journals");

export const getTradesAtom = atomFamily<string, Database<Trade>>((prefix) =>
  dbAtom(`trades:${prefix}`)
);

export const useTrades = (journalId: string) => {
  return useAtomValue(getTradesAtom(journalId));
};

export const useInsertTrade = userbaseInsertHook<Trade>(
  (journal) => `trades:${journal}`
);
export const useUpdateTrade = userbaseUpdateHook<Trade>(
  (journal) => `trades:${journal}`
);
export const useDeleteTrade = userbaseDeleteHook(
  (journal) => `trades:${journal}`
);
