import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiAvatar,
  EuiHeaderLinks,
  EuiShowFor,
} from "@elastic/eui";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import React from "react";
import { showSettingsAtom, userAtom } from "../utils/state";
import HeaderLink from "./HeaderLink";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const setShowSettings = useUpdateAtom(showSettingsAtom);
  const { user } = useAtomValue(userAtom);

  return (
    <EuiHeader style={{ width: "100%" }} position="fixed">
      <EuiHeaderSectionItem>
        <EuiHeaderLogo iconType="crosshairs" className="mono">
          <EuiShowFor sizes={["m", "l", "xl"]}>calc.trade</EuiShowFor>
        </EuiHeaderLogo>
      </EuiHeaderSectionItem>
      <EuiHeaderSectionItem border="none">
        {user && (
          <>
            <EuiHeaderLinks>
              <HeaderLink href="/">Calculator</HeaderLink>
              <HeaderLink href="/journals">Journal</HeaderLink>
            </EuiHeaderLinks>
            <EuiHeaderSectionItemButton>
              <EuiAvatar name={user.username} size="m" />
            </EuiHeaderSectionItemButton>
          </>
        )}
        <EuiHeaderSectionItemButton onClick={() => setShowSettings(true)}>
          <EuiIcon type="menu" size="m" />
        </EuiHeaderSectionItemButton>
      </EuiHeaderSectionItem>
    </EuiHeader>
  );
};

export default Header;
