import { Market } from "ccxt";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { binance } from "../../utils/exchange";
import { useInsertTrade } from "../../utils/state";
import SymbolModal from "../SymbolModal";

interface CreateTradeProps {
  open: boolean;
  onClose: () => void;
  journalId: string;
}

const CreateTrade: React.FC<CreateTradeProps> = ({
  open,
  onClose,
  journalId,
}) => {
  const history = useHistory();
  const [insert, { loading }] = useInsertTrade(journalId);

  const insertTrade = async (symbol: string) => {
    const id = await insert({ symbol });
    history.push(`/journals/${journalId}/trade/${id}`);
  };

  return (
    <SymbolModal
      noOption
      title="New Trade"
      open={open}
      onClose={onClose}
      onSelect={insertTrade}
    />
  );
};

export default CreateTrade;
