import {
  EuiButton,
  EuiComboBox,
  EuiDatePicker,
  EuiDescribedFormGroup,
  EuiFieldNumber,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiMarkdownEditor,
} from "@elastic/eui";
import moment from "moment";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Trade } from "../../utils/state";

const defaultTagOptions = ["Liquidated", "DCA", "Aped", "Greedy", "Early exit"];

interface TradeFormProps {
  onSubmit: (trade: Trade) => void;
  trade: Trade;
  loading?: boolean;
}

const TradeForm: React.FC<TradeFormProps> = ({ onSubmit, trade, loading }) => {
  const { control, handleSubmit, watch, setValue } = useForm<Trade>({
    defaultValues: {
      name: "",
      symbol: "",
      entryDate: "",
      entryQuantity: "",
      entryPrice: "",
      exitDate: "",
      exitPrice: "",
      fees: "",
      notes: "",
      tags: [],
      ...trade,
    },
  });

  console.log(watch());

  return (
    <EuiForm component="form" onSubmit={handleSubmit(onSubmit)}>
      <EuiDescribedFormGroup
        title={<h3>Symbol</h3>}
        description={<p>The coin, symbol, or pair purchased in this trade.</p>}
      >
        <EuiFormRow label="Symbol">
          <Controller
            name="symbol"
            control={control}
            render={({ field }) => (
              <EuiFieldText
                placeholder="BTC/USDT"
                inputRef={field.ref}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </EuiFormRow>
      </EuiDescribedFormGroup>
      <EuiHorizontalRule />
      <EuiDescribedFormGroup
        title={<h3>Entry</h3>}
        description={
          <p>
            The entry points for this trade, including date and time, quantity
            and price per coin at the time entered.
          </p>
        }
      >
        <EuiFormRow label="Entry date">
          <Controller
            name="entryDate"
            control={control}
            render={({ field }) => (
              <EuiDatePicker
                showTimeSelect
                selected={field.value ? moment(field.value) : null}
                inputRef={field.ref}
                onChange={(date) => field.onChange(date?.toISOString())}
              />
            )}
          />
        </EuiFormRow>
        <EuiFormRow label="Quantity">
          <Controller
            name="entryQuantity"
            control={control}
            render={({ field }) => (
              <EuiFieldNumber
                value={field.value}
                inputRef={field.ref}
                onChange={field.onChange}
              />
            )}
          />
        </EuiFormRow>
        <EuiFormRow label="Price per coin">
          <Controller
            name="entryPrice"
            control={control}
            render={({ field }) => (
              <EuiFieldNumber
                prepend="$"
                value={field.value}
                inputRef={field.ref}
                onChange={field.onChange}
              />
            )}
          />
        </EuiFormRow>
      </EuiDescribedFormGroup>
      <EuiHorizontalRule />
      <EuiDescribedFormGroup
        title={<h3>Exit</h3>}
        description={
          <p>
            The exit points for this trade, including date and time and price
            per coin at the time exited.
          </p>
        }
      >
        <EuiFormRow label="Exit date">
          <Controller
            name="exitDate"
            control={control}
            render={({ field }) => (
              <EuiDatePicker
                showTimeSelect
                selected={field.value ? moment(field.value) : null}
                inputRef={field.ref}
                onChange={(date) => field.onChange(date?.toISOString())}
              />
            )}
          />
        </EuiFormRow>
        <EuiFormRow label="Price per coin">
          <Controller
            name="exitPrice"
            control={control}
            render={({ field }) => (
              <EuiFieldNumber
                prepend="$"
                value={field.value}
                inputRef={field.ref}
                onChange={field.onChange}
              />
            )}
          />
        </EuiFormRow>
        <EuiFormRow label="Fees">
          <Controller
            name="fees"
            control={control}
            render={({ field }) => (
              <EuiFieldNumber
                prepend="$"
                value={field.value}
                inputRef={field.ref}
                onChange={field.onChange}
              />
            )}
          />
        </EuiFormRow>
      </EuiDescribedFormGroup>
      <EuiHorizontalRule />
      <EuiDescribedFormGroup
        title={<h3>Notes</h3>}
        description={<p>Any additional notes</p>}
      >
        <EuiFormRow label="Tags">
          <Controller
            name="tags"
            control={control}
            render={({ field }) => {
              const current = field.value || [];
              const options = [...defaultTagOptions, ...current];
              return (
                <EuiComboBox
                  options={options.map((v) => ({ label: v }))}
                  selectedOptions={field.value?.map((v) => ({ label: v }))}
                  onCreateOption={(value) => {
                    setValue("tags", [...current, value]);
                  }}
                  onChange={(values) =>
                    setValue(
                      "tags",
                      values.map((v) => v.label)
                    )
                  }
                />
              );
            }}
          />
        </EuiFormRow>
      </EuiDescribedFormGroup>
        <EuiFormRow label="Notes" fullWidth>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <EuiMarkdownEditor
                initialViewMode="viewing"
                value={field.value || ""}
                onChange={field.onChange}
                aria-label="Trade notes"
              />
            )}
          />
        </EuiFormRow>
      <EuiFormRow>
        <EuiButton type="submit" isLoading={loading}>Save</EuiButton>
      </EuiFormRow>
    </EuiForm>
  );
};

export default TradeForm;
