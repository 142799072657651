import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiTitle,
} from "@elastic/eui";
import React, { FormEventHandler, useState } from "react";
import { useAddJournal } from "../../utils/state";

interface CreateJournalProps {
  open: boolean;
  onClose: () => void;
}

const CreateJournal: React.FC<CreateJournalProps> = ({ open, onClose }) => {
  const [name, setName] = useState("");
  const [balance, setBalance] = useState("");
  const [insert, { loading }] = useAddJournal();

  const onSubmit: FormEventHandler = evt => {
    evt.preventDefault();
    insert({ name, startingBalance: Number(balance) }).then(onClose);
  }

  return open ? (
    <EuiFlyout onClose={onClose} size="s" ownFocus>
      <EuiFlyoutHeader>
        <EuiTitle>
          <h2>Create Journal</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiForm onSubmit={onSubmit} component="form">
        <EuiFlyoutBody>
          <EuiFormRow label="Name">
            <EuiFieldText
              value={name}
              onChange={(evt) => setName(evt.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="Starting Balance">
            <EuiFieldNumber
              value={balance}
              step={0.01}
              onChange={(evt) => setBalance(evt.target.value)}
            />
          </EuiFormRow>
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton type="submit" isLoading={loading}>Create</EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiForm>
    </EuiFlyout>
  ) : null;
};

export default CreateJournal;
