import {
  EuiModal,
  EuiModalHeader,
  EuiTitle,
  EuiModalBody,
  EuiSelectable,
  EuiOutsideClickDetector,
} from "@elastic/eui";
import React, { useEffect, useState } from "react";
import { binance } from "../utils/exchange";

interface SymbolModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (symbol: string) => void;
  noOption?: boolean;
  title?: string;
}

const SymbolModal: React.FC<SymbolModalProps> = ({
  open,
  onClose,
  onSelect,
  noOption,
  title = "Select Symbol",
}) => {
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    binance
      .fetchMarkets()
      .then((markets) => {
        setLoading(true);
        const symbols = markets.map((m) => m.symbol);
        if (noOption) {
          symbols.unshift("None");
        }
        setOptions(symbols);
      })
      .finally(() => setLoading(false));
  }, [noOption]);

  return open ? (
    <EuiOutsideClickDetector onOutsideClick={onClose}>
      <EuiModal onClose={onClose} initialFocus="symbol-search">
        <EuiModalHeader>
          <EuiTitle>
            <h2>{title}</h2>
          </EuiTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiSelectable
            searchable
            singleSelection
            isLoading={loading}
            options={options.map((label) => ({ label }))}
            searchProps={{ autoFocus: true, id: "symbol-search" }}
            onChange={(items) => {
              const item = items.find((i) => i.checked);
              if (item?.label) {
                onSelect(item.label);
              }
            }}
          >
            {(list, search) => (
              <>
                {search}
                {list}
              </>
            )}
          </EuiSelectable>
        </EuiModalBody>
      </EuiModal>
    </EuiOutsideClickDetector>
  ) : null;
};

export default SymbolModal;
