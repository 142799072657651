import {
  EuiPage,
} from "@elastic/eui";
import { useAtomValue } from "jotai/utils";
import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { userAtom } from "../utils/state";
import { userbase } from "../utils/userbase";
import JournalPage from "./Journal";
import JournalsListPage from "./JournalsList";

interface JournalsPageProps {}

const JournalsPage: React.FC<JournalsPageProps> = () => {
  let { path } = useRouteMatch();
  const { user } = useAtomValue(userAtom);

  useEffect(() => {
    userbase.getDatabases().then(console.log);
  }, []);

  return (
    <EuiPage>
      {/* <EuiPageSideBar sticky>
        <EuiStat
          title="$1800"
          description="Portfolio"
          titleColor="secondary"
          style={{ padding: 12 }}
        />
      </EuiPageSideBar> */}
      <Switch>
        <Route exact path={path}>
          {user && <JournalsListPage />}
        </Route>
        <Route path={`${path}/:id`}>
          {user && <JournalPage />}
        </Route>
      </Switch>
    </EuiPage>
  );
};

export default JournalsPage;
