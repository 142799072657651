import { EuiLink, EuiLinkProps } from "@elastic/eui";
import React, { MouseEvent } from "react";
import { useHistory } from "react-router-dom";

const isModifiedEvent = (event: MouseEvent) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

const isLeftClickEvent = (event: MouseEvent) => event.button === 0;

// const isTargetBlank = (event: MouseEvent) => {
//   const target = event.target.getAttribute('target');
//   return target && target !== '_self';
// };

const Link: React.FC<EuiLinkProps> = ({ href, ...rest }) => {
  const history = useHistory();

  function onClick(event: MouseEvent) {
    if (event.defaultPrevented || !href) {
      return;
    }

    // Let the browser handle links that open new tabs/windows
    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    // Prevent regular link behavior, which causes a browser refresh.
    event.preventDefault();

    // Push the route to the history.
    history.push(href);
  }

  // Generate the correct link href (with basename accounted for)
  const newHref = history.createHref({ pathname: href });

  const props = { ...rest, href: newHref, onClick };

  return <EuiLink {...props} href={newHref as unknown as undefined} />
};

export default Link;
