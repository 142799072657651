import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
} from "@elastic/eui";
import { useAtomValue } from "jotai/utils";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import TradeForm from "../components/journal/TradeForm";
import { getTradesAtom, useUpdateTrade } from "../utils/state";
interface TradePageProps {
  journalId: string;
}

const TradePage: React.FC<TradePageProps> = ({ journalId }) => {
  const { tradeId } = useParams<{
    journalId: string;
    tradeId: string;
  }>();
  const history = useHistory();

  const { data: trades } = useAtomValue(getTradesAtom(journalId));
  const [update, { loading }] = useUpdateTrade(journalId);
  const trade = trades.find(({ itemId }) => tradeId === itemId)?.item;

  if (!trade) {
    return <div>NOPE</div>;
  }

  const onSubmit = async (data: any) => {
    await update(tradeId, data);
    history.push(`/journals/${journalId}`);
  };

  return (
    <EuiFlyout onClose={() => history.push(`/journals/${journalId}`)} ownFocus>
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="l">
          <h1>Trade</h1>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <TradeForm onSubmit={onSubmit} trade={trade} loading={loading} />
      </EuiFlyoutBody>
    </EuiFlyout>
  );
};

export default TradePage;
