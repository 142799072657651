import {
  EuiButton,
  EuiInMemoryTable,
  EuiButtonIcon,
  EuiStat,
  EuiSpacer,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiBreadcrumbs,
  EuiPageHeaderSection,
  EuiTitle,
  EuiBadge,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
} from "@elastic/eui";
import { useAtomValue } from "jotai/utils";
import React, { useState } from "react";
import {
  useParams,
  useHistory,
  useRouteMatch,
  Switch,
  Route,
} from "react-router-dom";
import CreateTrade from "../components/journal/CreateTrade";
import Link from "../components/Link";
import { getTradesAtom, journalDbAtom, useDeleteTrade } from "../utils/state";
import { useKey } from "react-use";
import { pnl } from "../utils/ops";
import TradePage from "./TradeFlyout";

interface JournalPageProps {}

const getTradePnl = ({ item: t }: any) => {
  if (t.entryPrice && t.entryQuantity && t.exitPrice) {
    return pnl(t.entryQuantity, t.entryPrice, t.exitPrice, true);
  } else {
    return 0;
  }
};

const JournalPage: React.FC<JournalPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [newTradeOpen, setNewTradeOpen] = useState(false);
  const { data: trades, loading } = useAtomValue(getTradesAtom(id));
  const journal = useAtomValue(journalDbAtom(id));
  const [deleteTrade] = useDeleteTrade(id);

  useKey("n", () => setNewTradeOpen(true));

  if (!journal) {
    return null;
  }

  const totalPnl = trades.map(getTradePnl).reduce((l, r) => l + r, 0);
  const totalFees = trades
    .map((t) => Number(t.item.fees))
    .reduce((l, r) => l + r, 0);
  const startingBalance = journal.item.startingBalance;
  const balance = startingBalance + totalPnl - totalFees;

  return (
    <EuiPageBody>
      <EuiPageHeader restrictWidth paddingSize="l">
        <EuiPageHeaderSection>
          <EuiBreadcrumbs
            breadcrumbs={[
              { text: "Journals", onClick: () => history.push("/journals") },
              {
                text: "Trades",
              },
            ]}
            truncate={false}
            aria-label="An example of EuiBreadcrumbs"
          />
          <EuiTitle size="l">
            <h1>{journal.item.name}</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
        <EuiPageHeaderSection>
          <EuiButton onClick={() => setNewTradeOpen(true)}>New Trade</EuiButton>
        </EuiPageHeaderSection>
      </EuiPageHeader>
      <EuiPageContent hasShadow={false} hasBorder={false}>
        <EuiPageContentBody restrictWidth>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiStat
                title={`$${balance.toFixed(2)}`}
                titleColor={balance < 0 ? "danger" : "secondary"}
                description="Balance"
                isLoading={loading}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiStat
                titleSize="s"
                title={`$${totalPnl.toFixed(2)}`}
                titleColor={totalPnl < 0 ? "danger" : "secondary"}
                description="PnL"
                isLoading={loading}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiStat
                titleSize="s"
                title={`-$${totalFees.toFixed(2)}`}
                titleColor="danger"
                description="Fees"
                isLoading={loading}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiStat
                titleSize="s"
                title={trades.length}
                description="Trades"
                isLoading={loading}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPageContentBody>
      </EuiPageContent>
      <EuiSpacer size="xl" />
      <EuiPageContent hasShadow={false} hasBorder={false}>
        <EuiPageContentBody restrictWidth>
          <EuiInMemoryTable
            loading={loading}
            pagination
            columns={[
              {
                field: "item.symbol",
                name: "Symbol",
                render: (name, row) => (
                  <Link href={`/journals/${id}/trade/${row.itemId}`}>
                    {name}
                  </Link>
                ),
              },
              {
                field: "createdBy.timestamp",
                name: "Created At",
                dataType: "date",
              },
              {
                name: "Status",
                render: ({ item: row }: any) => {
                  console.log(row);
                  const status =
                    row.entryPrice && row.exitPrice ? "Closed" : "Active";
                  return (
                    <EuiBadge
                      color={status === "Closed" ? "secondary" : "ghost"}
                    >
                      {status}
                    </EuiBadge>
                  );
                },
              },
              {
                name: "PNL",
                render: ({ item: row }: any) => {
                  if (row.entryPrice && row.entryQuantity && row.exitPrice) {
                    const profit = pnl(
                      row.entryQuantity,
                      row.entryPrice,
                      row.exitPrice,
                      true
                    );
                    return (
                      <EuiText color={profit < 0 ? "danger" : "secondary"}>
                        ${profit.toFixed(2)}
                      </EuiText>
                    );
                  }
                  return null;
                },
              },
              {
                name: "Actions",
                align: "right",
                render: ({ itemId }: any) => {
                  return (
                    <EuiButtonIcon
                      iconType="trash"
                      color="danger"
                      aria-label="Delete trade"
                      onClick={() => deleteTrade(itemId)}
                    />
                  );
                },
              },
            ]}
            items={trades}
          />
        </EuiPageContentBody>
      </EuiPageContent>
      <Switch>
        <Route exact path={`${path}/trade/:tradeId`}>
          <TradePage journalId={id} />
        </Route>
      </Switch>
      <CreateTrade
        open={newTradeOpen}
        onClose={() => setNewTradeOpen(false)}
        journalId={id}
      />
    </EuiPageBody>
  );
};

export default JournalPage;
