import { useAtom } from "jotai";
import {
  showSettingsAtom,
  showSignInAtom,
  showSignUpAtom,
  userAtom,
} from "../utils/state";
import React from "react";
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiSpacer,
  EuiTitle,
} from "@elastic/eui";
import DCASetting from "./DCASetting";
import { useUpdateAtom } from "jotai/utils";
import userbase from "userbase-js";
import { useHistory } from "react-router-dom";

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = () => {
  const [showSettings, setShowSettings] = useAtom(showSettingsAtom);
  const history = useHistory();
  const [auth, setAuth] = useAtom(userAtom);
  const setShowSignIn = useUpdateAtom(showSignInAtom);
  const setShowSignUp = useUpdateAtom(showSignUpAtom);

  const signOut = async () => {
    try {
      await userbase.signOut();
      history.push("/");
      setAuth({ ...auth, user: null });
      setShowSettings(false);
    } catch (err) {
      alert(err.message);
    }
  };

  return showSettings ? (
    <EuiFlyout
      size="s"
      onClose={() => setShowSettings(false)}
      ownFocus
      paddingSize="l"
    >
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2 className="mono">calc.trade</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        {auth.user ? (
          <EuiButton color="text" onClick={signOut}>
            Sign out
          </EuiButton>
        ) : (
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiButton
                onClick={() => {
                  setShowSettings(false);
                  setShowSignUp(true);
                }}
              >
                Sign Up
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButtonEmpty
                color="text"
                onClick={() => {
                  setShowSettings(false);
                  setShowSignIn(true);
                }}
              >
                Sign In
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
        <EuiSpacer />
        <EuiTitle size="s">
          <h3>Settings</h3>
        </EuiTitle>
        {/* <EuiSpacer />
        <EuiSwitch
          id="show-chart"
          checked={showChart}
          onChange={(e: any) => setShowChart(e.target.checked)}
          // labelPlacement={LABEL_PLACEMENT.right}
          // checkmarkType={STYLE_TYPE.toggle_round}
          label="Show Chart"
        /> */}
        <EuiSpacer />
        <DCASetting />
      </EuiFlyoutBody>
    </EuiFlyout>
  ) : null;
};

export default Settings;
