import {
  EuiButton,
  EuiButtonIcon,
  EuiInMemoryTable,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
} from "@elastic/eui";
import { useAtomValue } from "jotai/utils";
import React, { useEffect, useState } from "react";
import CreateJournal from "../components/journal/CreateJournal";
import Link from "../components/Link";
import { journalsDbAtom, useDeleteJournal } from "../utils/state";
import { userbase } from "../utils/userbase";

interface JournalsListPageProps {}

const JournalsListPage: React.FC<JournalsListPageProps> = () => {
  const [createJournalOpen, setCreateJournalOpen] = useState(false);
  const { data: journals, loading } = useAtomValue(journalsDbAtom);
  const [deleteJournal] = useDeleteJournal();

  useEffect(() => {
    userbase.getDatabases().then(console.log);
  }, []);

  return (
    <EuiPageBody>
      <EuiPageHeader
        pageTitle="Journals"
        paddingSize="l"
        restrictWidth
        rightSideItems={[
          <EuiButton onClick={() => setCreateJournalOpen(true)}>
            New Journal
          </EuiButton>,
        ]}
      />
      <EuiPageContent hasBorder={false} hasShadow={false}>
        <EuiPageContentBody paddingSize="l" restrictWidth>
          <EuiInMemoryTable
            loading={loading}
            columns={[
              {
                field: "item.name",
                name: "Journal",
                render: (name, row) => (
                  <Link href={`/journals/${row.itemId}`}>{name}</Link>
                ),
              },
              {
                field: "createdBy.timestamp",
                name: "Created At",
                dataType: "date",
              },
              {
                field: "item.startingBalance",
                name: "Starting Balance",
                dataType: "number",
              },
              {
                field: "blah",
                name: "Actions",
                align: "right",
                render: (_, { itemId }) => {
                  return (
                    <EuiButtonIcon
                      iconType="trash"
                      color="danger"
                      aria-label="Delete journal"
                      onClick={() => deleteJournal(itemId)}
                    />
                  );
                },
              },
            ]}
            items={journals}
          />
        </EuiPageContentBody>
      </EuiPageContent>
      <CreateJournal
        open={createJournalOpen}
        onClose={() => setCreateJournalOpen(false)}
      />
    </EuiPageBody>
  );
};

export default JournalsListPage;
